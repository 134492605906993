const constants = {
    space: {
        XXS: '4px',
        XS: '8px',
        S: '16px',
        M: '24px',
        L: '32px',
        XL: '48px'
    },
    colors: {
        surface: {
            level0: 'black',
            level1: 'rgba(0,0,0,0.7)',
            level2: 'rgba(0,0,0,0.5)',
            level3: 'rgba(0,0,0,0.3)'
        },
        text: {
            level0: 'white',
            level1: 'rgba(255,255,255,0.7)'
        }
    }
};

export default constants;
