import React from 'react';
import PageHeader from '../common/page.header';

const Search = () => {
    return (
        <div className='p-5'>
            <PageHeader name='Search' />
        </div>
    );
};

export default Search;
